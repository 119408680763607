import React from "react"
import { Link } from "gatsby"
import styles from "./blog.module.scss"
const BlogTags = ({ tagsArr }) => {
  return (
    <article className="container" style={{ paddingBottom: "60px" }}>
      {tagsArr.length ? <strong>Tags: </strong> : undefined}
      {tagsArr.map((tag, i) => (
        <>
          <Link
            className={styles.tag}
            key={tag.id}
            to={`/blog/tags/${tag.elements.slug.value}`}
          >
            {tag.elements.tag.value}
          </Link>
          {i === tagsArr.length - 1 ? "" : <span> | </span>}
        </>
      ))}
    </article>
  )
}

export default BlogTags
