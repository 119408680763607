import React from "react"
import { Link } from "gatsby"
import styles from "./blog.module.scss"
const BlogHero = ({ title, published, slug, category }) => {
  return (
    <section className={`container ${styles.hero}`}>
      <h1>{title}</h1>
      <p>
        {published} -{" "}
        <Link className="category__link" to={`/blog/categories/${slug}`}>
          {category}
        </Link>
      </p>
    </section>
  )
}

export default BlogHero
